import {Page} from '../../type/Page';

class Login implements Page
{
	public constructor()
	{
	}

	public init()
	{
		this.togglePasswordVisibility();
	}

	private togglePasswordVisibility()
	{
		$('.js-toggleOpenPassTrigger').on('click', () =>
		{
			$('.js-toggleOpenPass').toggleClass('js-isOpened');

			if ($('.js-toggleOpenPass').hasClass('js-isOpened'))
			{
				$('.js-toggleOpenPass').find('[name="pass"]').prop('type', 'text');
			}
			else
			{
				$('.js-toggleOpenPass').find('[name="pass"]').prop('type', 'password');
			}
		});
	}
}

const createPage = (): Page|undefined =>
{
	const params = new URLSearchParams(location.search);
	const pageId = params.get('c') ?? '';

	if (['login'].includes(pageId))
	{
		return new Login();
	}

	return undefined;
};

const page = createPage();

page?.init();
